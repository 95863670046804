/**
 * Created by andrey on 07.02.2022.
 */

FogsConfig.initialize = cleverapps.extendFunc(FogsConfig.initialize, function () {
    this._super();

    FogsConfig.main = {
        fog0: {
            id: 0,
            price: 0,
            exp: 0,
            headless: true
        },
        fog1: {
            id: 1,
            price: 0,
            exp: 0,
            headless: true
        },
        fog2: {
            id: 2,
            price: 5,
            exp: 10,
            autoOpen: true,
            conditions: {
                unit: { code: "wands", stage: 0, hidden: true }
            }
        },
        fog3: {
            id: 3,
            price: 5,
            exp: 10,
            parentFogId: "fog2",
            silentUnblock: true,
            conditions: {
                level: 3
            }
        },
        fog4: {
            id: 4,
            price: 5,
            exp: 20,
            parentFogId: "fog3",
            conditions: {
                level: 4
            }
        },
        fog5: {
            id: 5,
            price: 15,
            exp: 50,
            parentFogId: "fog4",
            conditions: {
                level: 5
            }
        },
        fog6: {
            id: 6,
            price: 20,
            exp: 50,
            parentFogId: "fog5",
            conditions: {
                level: 6,
                hero: "king"
            }
        },

        moneyFog1: {
            id: 23,
            price: 240,
            windowSkin: "green",
            money: true,
            parentFogId: "fog5"
        },
        moneyFog2: {
            id: 24,
            price: 699,
            windowSkin: "green",
            noTerrainInBuyWindow: true,
            money: true,
            parentFogId: "fog6",
            conditions: {
                hero: "king"
            }
        },
        moneyFog3: {
            id: 25,
            price: 899,
            windowSkin: "green",
            noTerrainInBuyWindow: true,
            money: true,
            parentFogId: "fog7",
            conditions: {
                hero: "king"
            }
        }
    };

    FogsConfig.afterInitialize();
});

FogsConfig.initialize();

/**
 * Created by mac on 2/28/23
 */

var GardenScene = NoneMainGameScene.extend({
    onSceneLoaded: function () {
        this._super();
    },

    listBundles: function () {
        var result = this._super();
        result.push("units_main");
        return result;
    },

    animateZoom: function () {
    }
});

GameScene = GardenScene;

cleverapps.styles.GardenScene = {
};